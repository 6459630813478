import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

const checkAuth = async () => {
    return axios
        .get('/api/users/me')
        .then((res) => {
            return res.data;
        })
};

export function useAuthQuery() {
    return useQuery({
        queryKey: ['userAuth'],
        queryFn: checkAuth,
        retry: 0,
    })
};

const saveUser = async (body: any) => {
    return axios.put('/api/users', body);
}

export function useSaveUser({
    name,
    email,
    phone,
    pronouns,
    profile_picture_url,
    preferences,
    wishlist,
    history,
}: any) {
    const queryClient = useQueryClient();
    const mutation: any = useMutation({
        mutationFn: saveUser,
        onSuccess: () => {
            setTimeout(() => {
                queryClient.invalidateQueries({
                    queryKey: ['userAuth'],
                    refetchType: 'all',
                })
            }, 500);
        },
    });
    return {
        saveUser: async () => {
            return mutation.mutate({
                name,
                email,
                phone,
                pronouns,
                profile_picture_url,
                preferences,
                wishlist,
                history,
            });
        },
        reset: mutation.reset,
        isError: mutation.isError,
        isPending: mutation.isPending,
        isSuccess: mutation.isSuccess,
    };
};

// fetch('/api/users', {
//     method: 'PUT',
//     mode: 'cors',
//     headers: {
//         'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({
//         first_name: 'Robert',
//         last_name: 'Odil',
//         nickname: 'Lord Quagmire',
//         profile_picture_url: '/avatars/031.png',
//         preferences: {},
//         wishlist: [],
//         history: [],
//     })
// })
// .then(response => response.json())
// .then(data => console.log(data))
// .catch(error => console.error('Error:', error));


const logIn = async (body: { email: string, password: string }) => {
    return axios.post('/api/users/login', body);
};


export function useLogin({ email, password }: { email: string, password: string }) {
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: logIn,
        onSuccess: () => {
            setTimeout(() => {
                queryClient.invalidateQueries({
                    queryKey: ['userAuth'],
                    refetchType: 'all',
                })
            }, 500);
        },
    });
    return {
        login: async () => {
            return mutation.mutate({ email, password });
        },
        reset: mutation.reset,
        isError: mutation.isError,
        isPending: mutation.isPending,
        isSuccess: mutation.isSuccess,
    };
};


const logOut = async () => {
    return axios.post('/api/users/logout');
};

export function useLogout() {
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: logOut,
        onSuccess: () => {
            setTimeout(() => {
                queryClient.invalidateQueries({
                    queryKey: ['userAuth'],
                    refetchType: 'all',
                })
            }, 500);
        },
    });
    return {
        logout: async () => {
            return mutation.mutate();
        },
        reset: mutation.reset,
        isError: mutation.isError,
        isPending: mutation.isPending,
        isSuccess: mutation.isSuccess,
    };
};


type SignupBody = {
    email: string;
    password: string;
};

const signup = async (body: SignupBody) => {
    return axios.post('/api/users/register', body);
};

export function useSignup(content: SignupBody) {
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: signup,
        onSuccess: () => {
            setTimeout(() => {
                queryClient.invalidateQueries({
                    queryKey: ['userAuth'],
                    refetchType: 'all',
                })
            }, 500);
        },
    });
    return {
        signup: async () => {
            return mutation.mutate(content);
        },
        reset: mutation.reset,
        isError: mutation.isError,
        isPending: mutation.isPending,
        isSuccess: mutation.isSuccess,
    };
};

const emailAvailable = async (email: string) => {
    return axios.post('/api/users/checkemail', { email });
};

export function useEmailAvailable(email: string) {
    const mutation = useMutation({
        mutationFn: emailAvailable,
    });
    return {
        checkEmail: async () => {
            return mutation.mutate(email);
        },
        reset: mutation.reset,
        isError: mutation.isError,
        isPending: mutation.isPending,
        isSuccess: mutation.isSuccess,
    };
};