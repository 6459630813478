import { Box, Heading, Image, Text, Container, HStack, Skeleton, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger } from '@chakra-ui/react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BookCardComingSoon } from '../partials/BookCardComingSoon';


export default function ComingSoon() {
    const { id } = useParams();
    const [blog, setBlog] = useState<any>([]);


    useEffect(() => {
        axios.get(`/api/blogs/${id}`)
            .then((result) => {
                setBlog(result.data);
            })
    }, [id]);

    const content: any[] = [];
    if (blog.content) {
        blog.content.split(/\r?\n/).forEach((line: string) => {
            if (line.match(/^## /)) {
                content.push(<Heading fontWeight="bold" size="h3">{line.replace(/^## /, '')}</Heading>)
            } else {
                content.push(<Text>{line}<br /></Text>);
            }
        });
    } else {
        return (<>
            <Container maxW="1000px" mt="80px" mb="80px">
                <Skeleton height="20px" mb="10px" startColor='var(--disabled-text)' endColor='var(--primary-text)' />
                <Skeleton height="20px" mb="10px" startColor='var(--disabled-text)' endColor='var(--primary-text)' />
                <Skeleton height="20px" mb="10px" startColor='var(--disabled-text)' endColor='var(--primary-text)' />
                <Skeleton height="120px" mb="30px" startColor='var(--disabled-text)' endColor='var(--primary-text)' />
                <Skeleton height="20px" mb="30px" startColor='var(--disabled-text)' endColor='var(--primary-text)' />
                <Skeleton height="165px" mb="50px" startColor='var(--disabled-text)' endColor='var(--primary-text)' />
                <Skeleton height="500px" mb="50px" startColor='var(--disabled-text)' endColor='var(--primary-text)' />
            </Container>
        </>);
    }

    return (
        <>
            <Container maxW="1000px" mt="80px" mb="80px">
                <HStack align="start" gap="30px">
                    <Box>
                        <Text mb="20px">{blog.date}&nbsp;&nbsp;|&nbsp;&nbsp;5 minute read</Text>
                        <Heading size="h2" mb="20px">{blog.title}</Heading>
                        <Text mb="30px">Article by
                            <Popover>
                                <PopoverTrigger>
                                    <a href="/"><Text display="inline" ml="10px">D. Whiskers</Text></a>
                                </PopoverTrigger>
                                <PopoverContent>
                                    <PopoverArrow />
                                    <PopoverCloseButton />
                                    <PopoverHeader>Staff Writer</PopoverHeader>
                                    <PopoverBody>
                                        <HStack align="start" gap="20px">
                                            <Image src="/basic_avatars/011.png" width="80px" height="auto" borderRadius="xl" boxShadow="2px 2px 10px -6px rgba(0,0,0,0.75)" />
                                            <Text>
                                                <strong>Dakota Whiskers</strong><br />
                                                Staff writer
                                            </Text>
                                        </HStack>
                                    </PopoverBody>
                                </PopoverContent>
                            </Popover>
                            , posted on {blog.date}</Text>
                    </Box>
                </HStack>
                <Text mb="30px">{blog.intro}</Text>
                <BookCardComingSoon blog={blog} />
                {content}
                <Heading size="h3" fontWeight="bold" mt="30px">
                    Get this book today!
                </Heading>
                <Text>
                    If you're looking for a captivating and thought-provoking read, "{blog.bookTitle}" is a must-have. You can purchase it from <a href={blog.buyLink} target="_blank" rel="noreferrer">Amazon through our affiliate link</a>. It won't cost you anything extra, but it will help support our site. Thank you for supporting us through your purchase!
                </Text>
            </Container>

        </>
    );
}
