import {
    Container,
    Heading,
    Text,
    Box,
    Flex,
    Step,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    useMediaQuery,
    Button,
    Stack,
    Progress,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    DrawerCloseButton,
    Tooltip,
    WrapItem,
} from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useReducer, useRef, useState, useLayoutEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import useResizeObserver from 'use-resize-observer';

import { genresAndAuthors } from '../data/genresAndAuthors';
import { BubblePicker } from '../partials/BubblePicker';
import { useSaveRecommend } from '../queries/recommend';
import commaDelimit from '../utils/commaDelimit';

const steps = [
    { title: 'Genres', shortTitle: 'Genres' },
    { title: 'Themes', shortTitle: 'Themes' },
    { title: 'Authors', shortTitle: 'Authors' },
    { title: 'Customize', shortTitle: 'Customize' },
    { title: 'Review & Confirm', shortTitle: 'Confirm' },
];

const storedState = JSON.parse(window.localStorage.getItem('recommend') || `{
    "genres": [],
    "bookLength": [],
    "bookFormat": []
}`);
const initialState: any = {
    genres: storedState.genres,
    direction: -1,
    step: 0,
    bookLength: storedState.bookLength,
    bookFormat: storedState.bookFormat,
};
function reducer(state: any, action: any): any {
    const storeAndReturn = (toStorage: any): any => {
        window.localStorage.setItem('recommend', JSON.stringify({ genres: toStorage.genres, bookLength: toStorage.bookLength, bookFormat: toStorage.bookFormat }));
        return toStorage;
    };
    const newState: any = {
        step: state.step,
        direction: state.direction,
        genres: state.genres,
        bookLength: state.bookLength,
        bookFormat: state.bookFormat,
    };
    switch (action.type) {
        case 'HYDRATE':
            return storeAndReturn({ ...newState, ...action.payload, direction: state.direction });
        case 'REAL_STEP':
            newState.step = action.payload;
            return storeAndReturn(newState);
        case 'STEP_BACK':
            newState.nextStep = state.step - 1;
            newState.direction = 1;
            return storeAndReturn(newState);
        case 'STEP_FORWARD':
            newState.nextStep = state.step + 1;
            newState.direction = -1;
            return storeAndReturn(newState);
        case 'SET_GENRES':
            newState.genres = [];
            const genres = action.payload;
            genres.forEach((genre: string) => {
                const existing = state.genres.find((item: any) => item.title === genre);
                if (existing) {
                    newState.genres.push(existing);
                    return;
                }
                newState.genres.push({ title: genre, subGenres: [] });
            });
            newState.genres.sort((a: any, b: any) => a.title.localeCompare(b.title));
            return storeAndReturn(newState);
        case 'SET_SUB_GENRES':
            newState.genres = state.genres;
            const genre: any = state.genres.find((item: any) => item.title === action.payload.genre);
            const existingSubGenres = [...genre.subGenres];
            genre.subGenres = [];

            const subGenres = action.payload.selectedItems;
            subGenres.forEach((subGenreName: string) => {
                const existing = existingSubGenres.find((item: any) => item.title === subGenreName);
                if (existing) {
                    genre.subGenres.push(existing);
                    return;
                }
                genre.subGenres.push({ title: subGenreName, authors: [] });
            });
            return storeAndReturn(newState);
        case 'SET_AUTHORS':
            newState.genres = state.genres;
            const selectedGenre = state.genres.find((item: any) => item.title === action.payload.genre);
            if (action.payload.subGenre === '') {
                selectedGenre.authors = [...action.payload.selectedItems];
                return storeAndReturn(newState);
            }
            const subGenre = selectedGenre?.subGenres.find((item: any) => item.title === action.payload.subGenre);
            subGenre.authors = action.payload.selectedItems;
            return storeAndReturn(newState);
        case 'SET_BOOK_LENGTH':
            newState.bookLength = action.payload;
            return storeAndReturn(newState);
        case 'SET_BOOK_FORMAT':
            newState.bookFormat = action.payload;
            return storeAndReturn(newState);
        default:
            return state;
    }
};
function forwardDisabled(state: any) {
    if (state.step === 0) {
        return state.genres.length === 0;
    }
    return false;
}

const useIsOverflow = (
    ref: React.RefObject<HTMLElement>,
    step: number
): boolean => {
    const [isOverflowing, setIsOverflowing] = useState<boolean>(false);

    const checkOverflow = useCallback(() => {
        if (ref.current) {
            const isOverflowing =
                ref.current.scrollHeight > ref.current.clientHeight;

            setIsOverflowing(isOverflowing);
        }
    }, [ref]);

    useLayoutEffect(() => {
        if (!ref.current) return;
        setTimeout(() => {
            checkOverflow();
        }, 500);
    }, [ref, step, checkOverflow]);

    return isOverflowing;
};

function RecommendWizard({ userData }: any): JSX.Element {
    const [recommendOpen, setRecommendOpen] = useState(false);
    const [state, dispatch] = useReducer(reducer, initialState);
    const [isLargerThan800] = useMediaQuery('(min-width: 800px)');
    const [isLargerThan550] = useMediaQuery('(min-width: 550px)');
    const { ref, height } = useResizeObserver<HTMLDivElement>();
    const isMounted = useRef(false);
    const navigate = useNavigate();
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const isOverflow = useIsOverflow(scrollRef, state.step);

    useEffect(() => {
        const handleHashChange = () => {
            if (window.location.hash === '#recommendation') {
                setRecommendOpen(true);
            } else {
                setRecommendOpen(false);
            }
        };

        window.addEventListener('hashchange', handleHashChange);

        // Initial check if the modal should be open based on the current hash
        if (window.location.hash === '#recommendation') {
            setRecommendOpen(true);
        }

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, [recommendOpen, setRecommendOpen]);

    useEffect(() => {
        const handleResize = () => {
            document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        isMounted.current = true;
        return () => {
            window.removeEventListener('resize', handleResize)
            isMounted.current = false;
        };
    }, []);

    const selectedGenres = state.genres.map((genre: any) => {
        const subGenres = genresAndAuthors.find((item) => item.title === genre.title)?.subGenres || [];
        return subGenres ? { title: genre.title, subGenres } : [];
    });

    useEffect(() => {
        if (state.nextStep >= 0) {
            dispatch({ type: 'REAL_STEP', payload: state.nextStep });
            window.scrollTo({
                top: 0,
                left: 0,
            });
        }
    }, [state.nextStep, dispatch])

    const generateRecommendation = () => {
        if (!userData) {
            window.localStorage.setItem('autosubmitrecommend', 'yes');
            window.location.hash = '';
            navigate('/signup');
            (window as any).closeRecommend();
            return;
        }
        if (userData.status === 'pending-approval') {
            window.location.hash = '';
            navigate('/verify');
            return;
        }
        saveRecommend();
    };


    const { saveRecommend, isPending, isSuccess, response, isError } = useSaveRecommend({
        recommendation: { genres: state.genres, bookLength: state.bookLength, bookFormat: state.bookFormat },
    }) as any;

    useEffect(() => {

    }, [isError]);

    useEffect(() => {
        if (isSuccess) {
            window.localStorage.removeItem('recommend');
            navigate(`/recommendation/${response?.data?.id}`);
            (window as any).closeRecommend();
        }
    }, [isSuccess, response, navigate]);

    if (isPending) {
        return <Container maxW="1000px">Saving...</Container>;
    }

    const sortedGenres = [...state.genres].sort((a, b) => a.title > b.title ? 1 : -1);

    return (
        <Drawer placement="bottom" size="full" isOpen={recommendOpen} onClose={function (): void {
            window.location.hash = '';
        }}>
            <DrawerOverlay />
            <DrawerContent height={`calc(var(--vh, 1vh) * 100)`}>
                <DrawerCloseButton />
                <DrawerHeader bg="var(--primary-background)" paddingTop={`env(safe-area-inset-top)`}>
                    <Container maxW="1000px" mt="20px">
                        <Heading size="h1" mb="20px">Get a Recommendation</Heading>
                        {!isLargerThan800 && (<div>
                            <Flex align="center" width="full" mb="4px">
                                {/* <CircularProgress value={(state.step / (steps.length - 1)) * 100} size='50px' thickness='4px' /> */}
                                <Text mr="10px" size="body1" flexGrow={1}>{steps[state.step].shortTitle}</Text>
                                <Text ml="10px" size="body1">{state.step + 1} of {steps.length}</Text>
                            </Flex>
                            <Progress value={(state.step / (steps.length - 1)) * 100} borderRadius="full" />
                        </div>)}
                        {isLargerThan800 && (
                            <Stepper orientation="horizontal" index={state.step}>
                                {steps.map((step, index) => (
                                    <Step key={index}>
                                        <StepIndicator>
                                            <StepStatus
                                                complete={<StepIcon />}
                                                incomplete={<StepNumber />}
                                                active={<StepNumber />}
                                            />
                                        </StepIndicator>
                                        <Box flexShrink='0'>
                                            <StepTitle>{step.title}</StepTitle>
                                        </Box>
                                        <StepSeparator />
                                    </Step>
                                ))}
                            </Stepper>
                        )}
                    </Container>
                </DrawerHeader>
                <DrawerBody ref={scrollRef} bg="var(--primary-background)">
                    <Container maxW="1000px">
                        <Box mt="0px" mb="200px" position="relative" style={{ minHeight: (height || 100) }}>
                            <AnimatePresence initial={false}>
                                {state.step === 0 && (<motion.div
                                    key={`step-${state.step}`}
                                    initial={{ x: -300 * state.direction, opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    exit={{ x: 300 * state.direction, opacity: 0 }}
                                    transition={{ type: "tween", duration: 0.3, ease: "easeOut" }}
                                    style={{ position: 'absolute' }}
                                >
                                    <Box ref={ref}>
                                        <BubblePicker
                                            noPref={false}
                                            key="genres"
                                            itemList={genresAndAuthors}
                                            selected={state.genres.map((genre: any) => genre.title)}
                                            onChange={(selectedItems: string[]) => {
                                                dispatch({ type: 'SET_GENRES', payload: selectedItems });
                                            }}
                                            title="What are your favorite genres?"
                                            includeOther={true}
                                            sortItems={true}
                                        />
                                    </Box>
                                </motion.div>)}
                            </AnimatePresence>
                            <AnimatePresence initial={false}>
                                {state.step === 1 && <motion.div
                                    key={`step-${state.step}`}
                                    initial={{ x: -300 * state.direction, opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    exit={{ x: 300 * state.direction, opacity: 0 }}
                                    transition={{ type: "tween", duration: 0.3, ease: "easeOut" }}
                                    style={{ position: 'absolute' }}
                                >
                                    <Box ref={ref}>
                                        {selectedGenres.map((genre: any) => {
                                            return <BubblePicker
                                                noPref={false}
                                                key={genre.title}
                                                itemList={genre.subGenres}
                                                selected={state.genres.find((item: any) => item.title === genre.title)?.subGenres.map((subGenre: any) => subGenre.title)}
                                                onChange={(selectedItems: string[]) => {
                                                    dispatch({ type: 'SET_SUB_GENRES', payload: { genre: genre.title, selectedItems } });
                                                }}
                                                title={`Which of these sub-genres of ${genre.title} do you like?`}
                                                includeOther={true}
                                                sortItems={true}
                                            />
                                        })}
                                    </Box>
                                </motion.div>}
                            </AnimatePresence>
                            <AnimatePresence initial={false}>
                                {state.step === 2 && <motion.div
                                    key={`step-${state.step}`}
                                    initial={{ x: -300 * state.direction, opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    exit={{ x: 300 * state.direction, opacity: 0 }}
                                    transition={{ type: "tween", duration: 0.3, ease: "easeOut" }}
                                    style={{ position: 'absolute' }}
                                >
                                    <Box ref={ref}>
                                        {state.genres.map((genre: any) => {
                                            const sourceGenre = genresAndAuthors.find((item) => item.title === genre.title);
                                            if (genre.subGenres.length === 0) {
                                                return <BubblePicker
                                                    noPref={false}
                                                    key={genre.title}
                                                    itemList={sourceGenre?.authors || []}
                                                    selected={genre?.authors}
                                                    onChange={(selectedItems: string[]) => {
                                                        dispatch({ type: 'SET_AUTHORS', payload: { genre: genre.title, subGenre: '', selectedItems } });
                                                    }}
                                                    title={`Which of these ${genre.title} authors do you like?`}
                                                    includeOther={true}
                                                    sortItems={true}
                                                />
                                            }
                                            return genre.subGenres.map((subGenre: any) => {
                                                const authors: any[] = sourceGenre?.subGenres.find((sub) => sub.title === subGenre.title)?.authors || [];
                                                return <BubblePicker
                                                    noPref={false}
                                                    key={subGenre.title}
                                                    itemList={authors}
                                                    selected={genre?.subGenres.find((sub: any) => sub.title === subGenre.title)?.authors}
                                                    onChange={(selectedItems: string[]) => {
                                                        dispatch({ type: 'SET_AUTHORS', payload: { genre: genre.title, subGenre: subGenre.title, selectedItems } });
                                                    }}
                                                    title={`Which of these ${subGenre.title} authors do you like?`}
                                                    includeOther={true}
                                                    sortItems={true}
                                                />
                                            }).flat();
                                        })}
                                    </Box>
                                </motion.div>}
                            </AnimatePresence>

                            <AnimatePresence initial={false}>
                                {state.step === 3 && <motion.div
                                    key={`step-${state.step}`}
                                    initial={{ x: -300 * state.direction, opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    exit={{ x: 300 * state.direction, opacity: 0 }}
                                    transition={{ type: "tween", duration: 0.3, ease: "easeOut" }}
                                    style={{ position: 'absolute' }}
                                >
                                    <Box ref={ref}>
                                        <Box>
                                            <BubblePicker
                                                noPref={true}
                                                itemList={[
                                                    { title: 'Short Stories' },
                                                    { title: 'Novellas' },
                                                    { title: 'Average-length novels' },
                                                    { title: 'Longer novels' },
                                                    { title: 'Epic-length novels' },
                                                ]}
                                                selected={state.bookLength}
                                                onChange={(selectedItems: string[]) => {
                                                    dispatch({ type: 'SET_BOOK_LENGTH', payload: selectedItems });
                                                }}
                                                title={`What book length do you prefer?`}
                                                includeOther={false}
                                                sortItems={false}
                                            />
                                            <BubblePicker
                                                noPref={true}
                                                itemList={[
                                                    { title: 'Paperback' },
                                                    { title: 'Hardcover' },
                                                    { title: 'Kindle Edition' },
                                                    { title: 'Audiobook' },
                                                    { title: 'Large Print' },
                                                ]}
                                                selected={state.bookFormat}
                                                onChange={(selectedItems: string[]) => {
                                                    dispatch({ type: 'SET_BOOK_FORMAT', payload: selectedItems });
                                                }}
                                                title={`What book format are you most interested in?`}
                                                includeOther={false}
                                                sortItems={false}
                                            />
                                        </Box>
                                    </Box>
                                </motion.div>}
                            </AnimatePresence>


                            <AnimatePresence initial={false}>
                                {state.step === 4 && <motion.div
                                    key={`step-${state.step}`}
                                    initial={{ x: -300 * state.direction, opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    exit={{ x: 300 * state.direction, opacity: 0 }}
                                    transition={{ type: "tween", duration: 0.3, ease: "easeOut" }}
                                    style={{ position: 'absolute', width: '100%' }}
                                >
                                    <Box ref={ref} w="full" mb="150px">
                                        <Heading size="h3" mt="15px" mb="30px">
                                            Does this look correct?
                                        </Heading>


                                        <Box
                                            role={'group'}
                                            p={6}
                                            w="100%"
                                            bg="var(--review-card)"
                                            borderColor="var(--review-outline)"
                                            borderWidth={1}
                                            rounded={'2xl'}
                                            pos={'relative'}
                                            mb="20px"
                                            boxShadow="2px 2px 10px -6px rgba(0,0,0,0.75)"
                                        >
                                            <Stack>
                                                <Heading size="h2" fontWeight="normal">
                                                    Book Preferences
                                                </Heading>
                                                <Heading size="h6">
                                                    {commaDelimit(
                                                        state.bookLength
                                                    )}
                                                    {state.bookLength.length === 0 && <>
                                                        Length: No Preference
                                                    </>}
                                                </Heading>
                                                <Text size="body1">
                                                    {commaDelimit(
                                                        state.bookFormat
                                                    )}
                                                    {state.bookFormat.length === 0 && <>
                                                        Format: No Preference
                                                    </>}
                                                </Text>
                                            </Stack>
                                        </Box>


                                        <Flex wrap="wrap" justify="flex-start" align="stretch" mt="20px" w="full">
                                            {sortedGenres.map((genre, index) => {
                                                // Calculate the number of items per row based on the total count
                                                let itemsPerRow = 1;
                                                if (isLargerThan550) {
                                                    itemsPerRow = 2;
                                                }
                                                if (isLargerThan800) {
                                                    itemsPerRow = 3;
                                                }
                                                // const itemsPerRow = sortedGenres.length <= 3 ? sortedGenres.length : 3;
                                                const boxWidth = itemsPerRow === 1 ? '100%' : itemsPerRow === 2 ? '49%' : '32%';
                                                // Adjust margin dynamically to fit the container width precisely
                                                const margin = itemsPerRow === 1 ? 0 : '2%';

                                                return (
                                                    <Box
                                                        key={index}
                                                        role={'group'}
                                                        p={6}
                                                        w={boxWidth} // Dynamic width based on number of items
                                                        bg="var(--review-card)"
                                                        borderColor="var(--review-outline)"
                                                        borderWidth={1}
                                                        rounded={'2xl'}
                                                        pos={'relative'}
                                                        mb="20px"
                                                        boxShadow="2px 2px 10px -6px rgba(0,0,0,0.75)"
                                                        ml={(index % itemsPerRow !== 0) ? margin : 0} // Add left margin except for the first box in each row
                                                    >
                                                        <Stack>
                                                            <Heading size="h2" fontWeight="normal" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" width="full">
                                                                {genre.title}
                                                            </Heading>

                                                            <Heading size="h6">
                                                                {genre.subGenres.length === 0 && <>No Themes</>}
                                                                {commaDelimit(
                                                                    genre.subGenres.map((g: any) => g.title)
                                                                )}
                                                            </Heading>
                                                            <Text size="body1">
                                                                {(genre.authors || []).length !== 0 && (
                                                                    commaDelimit(genre.authors.sort())
                                                                )}
                                                                {((genre.authors || []).length === 0 && genre.subGenres.reduce((p: string[], g: any) => [...p, ...g.authors], []).length === 0) && <>No Authors</>}
                                                                {commaDelimit(
                                                                    genre.subGenres.reduce((p: string[], g: any) => [...p, ...g.authors], []).sort()
                                                                )}
                                                            </Text>

                                                        </Stack>
                                                    </Box>
                                                );
                                            })}
                                        </Flex>
                                    </Box>
                                </motion.div>}
                            </AnimatePresence>
                        </Box>
                        <Box
                            position="fixed" bottom="0"
                            left="0" right="0"
                            bg="var(--primary-background)"
                            p="20px" borderTopWidth="1px"
                            borderTopColor="var(--header-divider)"
                            boxShadow={isOverflow ? "0px 0px 12px 0px var(--disabled-text)" : ''}
                            transition="box-shadow 0.5s linear"
                        >
                            <Container maxW="1000px">
                                <Flex justifyContent="space-between">
                                    <Button
                                        onClick={() => {
                                            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
                                            if (scrollTop > 0) {
                                                window.scrollTo({
                                                    top: 0,
                                                    left: 0,
                                                    behavior: 'smooth'
                                                });
                                                setTimeout(() => {
                                                    if (isMounted.current) dispatch({ type: 'STEP_BACK' });
                                                }, 600);
                                                return;
                                            }
                                            dispatch({ type: 'STEP_BACK' });
                                        }}
                                        isDisabled={state.step < 1}
                                    >Back</Button>
                                    {state.step !== 4 &&
                                        <Button
                                            onClick={() => {
                                                const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
                                                if (scrollTop > 0) {
                                                    window.scrollTo({
                                                        top: 0,
                                                        left: 0,
                                                        behavior: 'smooth'
                                                    });
                                                    setTimeout(() => {
                                                        if (isMounted.current) dispatch({ type: 'STEP_FORWARD' });
                                                    }, 600);
                                                    return;
                                                }
                                                dispatch({ type: 'STEP_FORWARD' });
                                            }}
                                            isDisabled={forwardDisabled(state)}
                                        >Continue</Button>
                                    }
                                    {state.step === 4 && <div>

                                        <WrapItem>
                                            <Tooltip label={userData?.status === 'pending-approval' ? 'Email verification is required' : ''} placement='top'>
                                                <Button
                                                    colorScheme='blue'
                                                    onClick={generateRecommendation}
                                                >Generate</Button>
                                            </Tooltip>
                                        </WrapItem>
                                    </div>}
                                </Flex>
                            </Container>
                        </Box>
                    </Container>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}

export default RecommendWizard;
