import { useQuery, useMutation } from '@tanstack/react-query';
import axios from 'axios';

const fetchRecommends = async () => {
    return axios
        .get(`/api/recommends`)
        .then((res) => res.data)
};

export function useRecommends() {
    return useQuery({
        queryKey: ['recommends'],
        queryFn: async () => {
            const data = await fetchRecommends()
            return data
        },
        retry: 0,
    })
};


const fetchRecommend = async (id: string) => {
    return axios
        .get(`/api/recommends/${id}`)
        .then((res) => res.data)
};

export function useSingleRecommend(id: string) {
    return useQuery({
        queryKey: ['recommend', id],
        queryFn: async () => {
            if (!id) throw new Error('No id provided');
            const data = await fetchRecommend(id)
            return data
        },
        retry: 0,
    })
};

// const postRecommend = async (body: { email: string, password: string }) => {
const postRecommend = async (body: any) => {
    return axios.post('/api/recommends', body);
};


export function useSaveRecommend({ recommendation, prompt }: any) {
    const mutation = useMutation({
        mutationFn: postRecommend,
        // Will need to invalidate the query cache for recommendations
        // onSuccess: () => {
        //     setTimeout(() => {
        //         queryClient.invalidateQueries({
        //             queryKey: ['userAuth'],
        //             refetchType: 'all',
        //         })
        //     }, 500);
        // },
    });
    return {
        saveRecommend: async () => {
            return mutation.mutate({ recommendation, prompt });
        },
        response: mutation.data,
        reset: mutation.reset,
        isError: mutation.isError,
        isPending: mutation.isPending,
        isSuccess: mutation.isSuccess,
    };
};
