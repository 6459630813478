import { Box, Heading, Image, Text, Button, Container, Stack, useMediaQuery, Alert, AlertDescription, AlertIcon, AlertTitle, Skeleton } from '@chakra-ui/react';
import axios from 'axios';
import { useEffect, useState } from 'react';

import librarian from '../images/librarian.png';
import LabeledInput from '../partials/LabeledInput';
import { BlogCardComingSoon } from '../partials/BlogCardComingSoon';

export default function ComingSoon() {
    const [isLargerThan750] = useMediaQuery("(min-width: 750px)");
    const [email, setEmail] = useState('');
    const [state, setState] = useState('default');
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        axios.get('/api/blogs')
            .then((result) => {
                const data = result.data.map((item: any) => {
                    return {
                        ...item,
                        sortDate: new Date(item.date).getTime(),
                    };
                }).sort((a: any, b: any) => {
                    return a.sortDate > b.sortDate ? -1 : 1;
                });
                setBlogs(data);
            })
    }, []);

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        try {
            axios
                .post('/api/users/mailing', { email })
                .then((res) => {
                    setState('success');
                    return res.data;
                })
                .catch((err) => {
                    if (err.response.data.message === 'Missing email address') {
                        setState('error');
                        return;
                    }
                    setState('bad-error');
                })
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <Container maxW="1000px" mt="80px">
                {!isLargerThan750 && (<>
                    <Heading size="h3" as="h1" mb="20px">Chapter Cats: Your Ultimate Reading Companion is Coming Soon!</Heading>
                    <Box width="full" height="250px" overflow="hidden" rounded="2xl" boxShadow="2px 2px 10px -6px rgba(0,0,0,0.75)">
                        <Image
                            marginTop="-80px"
                            width="full"
                            height="600px"
                            alt={'Cat in glasses reading a book, inviting book lovers to join a community for reviews, recommendations, and literary adventures.'}
                            objectFit={'cover'}
                            src={librarian}
                        />
                    </Box>
                </>)}
                <Stack direction="row">
                    {isLargerThan750 && (
                        <Box width="40%" height="375px" overflow="hidden" rounded="2xl" boxShadow="2px 2px 10px -6px rgba(0,0,0,0.75)">
                            <Image
                                width="600px"
                                height="600px"
                                mt="-80px"
                                alt={'Cat in glasses reading a book, inviting book lovers to join a community for reviews, recommendations, and literary adventures.'}
                                objectFit={'cover'}
                                src={librarian}
                            />
                        </Box>
                    )}
                    {(state === 'default' || state === 'error' || state === 'bad-error') && (
                        <Box width={isLargerThan750 ? '60%' : '100%'} ml={isLargerThan750 ? '40px' : ''} mt={isLargerThan750 ? '' : '20px'}>
                            <Stack spacing={4} width="full">
                                {isLargerThan750 && (
                                    <Heading size="h3">Your Ultimate Reading Companion is Coming Soon!</Heading>
                                )}
                                <Text>
                                    Get ready to dive into a world of literary adventures with Chapter Cats, the ultimate destination for book lovers and avid readers!
                                </Text>
                                <Text>
                                    Sign up now to be the first to know when we launch!
                                </Text>
                                {state === 'bad-error' && (
                                    <Alert status='error' borderRadius="xl">
                                        <AlertIcon />
                                        <AlertTitle>Error</AlertTitle>
                                        <AlertDescription>Something went terribly wrong!</AlertDescription>
                                    </Alert>
                                )}
                                {state === 'error' && (
                                    <Alert status='error' borderRadius="xl">
                                        <AlertIcon />
                                        <AlertTitle>Error</AlertTitle>
                                        <AlertDescription>A valid email address is required.</AlertDescription>
                                    </Alert>
                                )}

                                <Box width="full" mb="50px">
                                    <form onSubmit={handleSubmit}>
                                        <LabeledInput
                                            label="Email"
                                            variant="outline"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            type="email"
                                            mb="0px"
                                        />
                                        <Button w={222} mb="20px" mt="20px" width="full" type="submit" colorScheme='blue'>Join Mailing List</Button>
                                    </form>
                                </Box>
                            </Stack>
                        </Box>
                    )}
                    {state === 'success' && (
                        <Box width={isLargerThan750 ? '60%' : '100%'} ml={isLargerThan750 ? '40px' : ''}>
                            <Stack spacing={4} width="full">
                                <Heading size="h3">Thank You for Your Interest in Chapter Cats!</Heading>
                                <Text>
                                    Thank you for signing up to be notified about the Chapter Cats launch! We're excited to have you join us on this journey.
                                </Text>
                                <Text>
                                    As a member of our exclusive mailing list, you'll be the first to know when Chapter Cats goes live.
                                </Text>
                                <Text>
                                    Get ready for personalized book recommendations and a world of literary adventures tailored just for you. Stay tuned for our launch announcement, and we can't wait to welcome you to the Chapter Cats community!
                                </Text>
                            </Stack>
                        </Box>
                    )}
                </Stack>

                <Heading size="h4" mb="30px">In the meantime. . . Checkout out some of our latest blog posts</Heading>
                {(blogs || []).length === 0 && (<>
                    <Skeleton isLoaded={(blogs || []).length > 0} height="30px" startColor='var(--disabled-text)' endColor='var(--primary-text)' />
                    <Skeleton isLoaded={(blogs || []).length > 0} height="30px" startColor='var(--disabled-text)' endColor='var(--primary-text)' />
                    <Skeleton isLoaded={(blogs || []).length > 0} height="30px" startColor='var(--disabled-text)' endColor='var(--primary-text)' />
                    <Skeleton isLoaded={(blogs || []).length > 0} height="30px" startColor='var(--disabled-text)' endColor='var(--primary-text)' />
                    <Skeleton isLoaded={(blogs || []).length > 0} height="30px" startColor='var(--disabled-text)' endColor='var(--primary-text)' />
                </>)}

                {blogs.map((blog: any) => {
                    return <BlogCardComingSoon blog={blog} />
                })}
            </Container>
        </>
    );
}
