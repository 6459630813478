const commaDelimit = (list: string[]) => {
    let results = list.join(', ');
    const lastComma = results.lastIndexOf(',');
    const multiComma = results.lastIndexOf(',', lastComma - 1);
    if (lastComma !== -1) {
        if (multiComma !== -1) {
            results = results.substring(0, lastComma) + ', and' + results.substring(lastComma + 1);
        } else {
            results = results.substring(0, lastComma) + ' &' + results.substring(lastComma + 1);
        }
    }
    return results;
};

export default commaDelimit;