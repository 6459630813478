import { defineStyleConfig } from "@chakra-ui/react";

export const stepperTheme = defineStyleConfig({
	baseStyle: {
		indicator: {
			bgColor: "transparent",
			borderColor: "var(--arrow-outline)",
			color: "var(--primary-button)",
			borderWidth: 1,


			"&[data-status=active]": {
				bgColor: "transparent",
				color: "var(--primary-button)",
				borderColor: "var(--arrow-outline)",
				borderWidth: 1,
			},
			"&[data-status=incomplete]": {
				bgColor: "transparent",
				color: "var(--primary-button)",
				borderColor: "var(--disabled-fill)",
				borderWidth: 1,
			},
			"&[data-status=complete]": {
				bgColor: "var(--primary-button)",
				borderColor: "var(--primary-button)",
				color: "var(--white-chip)",
				svg: {
					fill: "var(--white-chip)",
				},
				borderWidth: 1,
			},
		},
        separator: {
			"&[data-orientation=vertical]": {
				"marginTop": "6px",
			},
			"&[data-status=active]": {
				bgColor: "var(--arrow-outline)",
			},
			"&[data-status=incomplete]": {
				bgColor: "var(--disabled-fill)",
			},
            "&[data-status=complete]": {
                bgColor: "var(--primary-button)",
            },
        },
		icon: {
			borderColor: "var(--arrow-outline)",
			color: "var(--primary-button)",
			_hover: {
				borderColor: "var(--primary-hover)",
			},
		},
		label: {
			color: "var(--header-fill)",
		},
		button: {
			bg: "var(--primary-button)",
			color: "var(--white-chip)",
			_hover: {
				bg: "var(--primary-hover)",
			},
		},
		progress: {
			bg: "var(--progress-unified)",
			filledTrack: {
				bg: "var(--progress-fill)",
			},
		},
	},
	variants: {
		active: {
			indicator: {
				bg: "var(--primary-button)",
			},
		},
	},
});
