import { Box, Stack, HStack, Text, VisuallyHidden, chakra, useColorModeValue, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { ReactNode } from 'react';
import { FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';

const Footer = () => {
    const reveal = window.localStorage.getItem('reveal');
    return (
        <Box
            mt="60px"
            bg="var(--header-fill)"
            className="footer-guy"
            boxShadow="0px 0px 9px 0px var(--disabled-text)"
        >
            <Stack
                maxW="5xl"
                marginInline="auto"
                p={8}
                spacing={{ base: 8, md: 0 }}
                justifyContent="space-between"
                alignItems="center"
                direction={{ base: 'column', md: 'row' }}
            >
                <Text size="sm">
                    © 2024 Chapter Cats. All rights reserved
                    {reveal && (
                        <Button onClick={() => {
                            window.localStorage.removeItem('reveal');
                            window.location.reload();
                        }} size="xs">Clear Reveal</Button>
                    )}
                </Text>
                <HStack spacing={4} alignItems="center" display={{ base: 'none', md: 'flex' }}>
                        <Link to="/privacy">Privacy Policy</Link>
                        <Link to="/terms">Terms of Service</Link>
                        <Link to="/data">Your Data</Link>
                </HStack>

                <Stack display={{ base: 'flex', md: 'none' }} alignItems="center">
                    <HStack alignItems="center">
                        <Link to="/privacy">Privacy Policy</Link>
                        <Link to="/terms">Terms of Service</Link>
                        <Link to="/data">Your Data</Link>
                    </HStack>
                </Stack>

                <Stack direction={'row'} spacing={6}>
                    <SocialButton label={'Twitter'} href={'https://twitter.com/'}>
                        <FaTwitter />
                    </SocialButton>
                    <SocialButton label={'YouTube'} href={'https://youtube.com'}>
                        <FaYoutube />
                    </SocialButton>
                    <SocialButton label={'Instagram'} href={'https://www.instagram.com'}>
                        <FaInstagram />
                    </SocialButton>
                </Stack>
            </Stack>
        </Box>
    );
};

const SocialButton = ({
    children,
    label,
    href,
}: {
    children: ReactNode
    label: string
    href: string
}) => {
    return (
        <chakra.button
            rounded={'full'}
            w={8}
            h={8}
            cursor={'pointer'}
            as={'a'}
            href={href}
            display={'inline-flex'}
            alignItems={'center'}
            justifyContent={'center'}
            target="_blank"
            _hover={{
                bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
            }}>
            <VisuallyHidden>{label}</VisuallyHidden>
            {children}
        </chakra.button>
    )
}

export default Footer;