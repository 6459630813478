import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import * as ReactDOMClient from 'react-dom/client';
import { ChakraProvider } from "@chakra-ui/react"

import reportWebVitals from './reportWebVitals';
import { Router } from './Router';
import { theme } from './themes/cat-theme';

import "./index.css";


const queryClient = new QueryClient();

const container = document.getElementById('root');
// Ensure the root container exists in the DOM before attempting to create a root.
if (container !== null) {

    const root = ReactDOMClient.createRoot(container);
    root.render(
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <ChakraProvider theme={theme}>
                    <Router />
                </ChakraProvider>
            </QueryClientProvider>
        </React.StrictMode>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
