import { Box, Stack, HStack, Text, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const ComingSoonFoot = () => {
    const reveal = window.localStorage.getItem('reveal');
    return (
        <Box
            mt="60px"
            bg="var(--header-fill)"
            className="footer-guy"
            boxShadow="0px 0px 9px 0px var(--disabled-text)"
        >
            <Stack
                maxW="5xl"
                marginInline="auto"
                p={8}
                spacing={{ base: 8, md: 0 }}
                justifyContent="space-between"
                alignItems="center"
                direction={{ base: 'column', md: 'row' }}
            >
                <Text size="sm">
                    © 2024 Chapter Cats. All rights reserved
                    {reveal && (
                        <Button onClick={() => {
                            window.localStorage.removeItem('reveal');
                            window.location.reload();
                        }} size="xs">Clear Reveal</Button>
                    )}
                </Text>
                <HStack spacing={4} alignItems="center" display={{ base: 'none', md: 'flex' }}>
                        <Link to="/affiliate-disclosure">Affiliate Disclosure</Link>
                </HStack>

                <Stack display={{ base: 'flex', md: 'none' }} alignItems="center">
                    <HStack alignItems="center">
                        <Link to="/affiliate-disclosure">Affiliate Disclosure</Link>
                    </HStack>
                </Stack>
            </Stack>
        </Box>
    );
};

export default ComingSoonFoot;