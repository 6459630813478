import {
    createBrowserRouter,
    RouterProvider,
    useLocation,
    useNavigate,
} from "react-router-dom";
import { Box, CircularProgress } from "@chakra-ui/react";
import { useCallback, useEffect, useState, lazy, Suspense, ReactElement } from "react";
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';

import { ErrorPage } from './pages/ErrorPage';
import { useAuthQuery } from './queries/user';

import { TopNav } from "./partials/TopNav";
import Footer from "./partials/Footer";
import { useHeartbeat } from "./queries/heartbeat";

// Lazy load page components
import ComingSoon from './pages/ComingSoon';
import { ComingSoonNav } from './partials/ComingSoonNav';
import ComingSoonFoot from "./partials/ComingSoonFoot";
import Blog from './pages/Blog';
import AffiliateDisclosure from './pages/AffiliateDisclosure';

const Privacy = lazy(() => import('./pages/Privacy'));
const EmailVerify = lazy(() => import('./pages/EmailVerify'));
const HomepageOut = lazy(() => import('./pages/HomepageOut'));
const HomepageIn = lazy(() => import('./pages/HomepageIn'));
const SearchPage = lazy(() => import('./pages/SearchPage'));
const YourData = lazy(() => import('./pages/YourData'));
const Terms = lazy(() => import('./pages/Terms'));
const RecommendDetails = lazy(() => import('./pages/RecommendDetails'));
const AboutUs = lazy(() => import('./pages/AboutUs'));
const ChatTest = lazy(() => import('./pages/ChatTest'));
const CreateClub = lazy(() => import('./pages/CreateClub'));
const Profile = lazy(() => import('./pages/Profile'));
const Login = lazy(() => import('./pages/Login'));
const Signup = lazy(() => import('./pages/Signup'));
const NotFound = lazy(() => import('./pages/404'));

declare global {
    interface Window {
        dataLayer: any[];
    }
}

const HeartBeat = () => {
    useHeartbeat();
    return null;
};

const AuthWrapper = ({ InComponent, OutComponent, SoonComponent }: any) => {
    const { pathname } = useLocation();
    const { isLoading, data } = useAuthQuery();
    const revealLS = window.localStorage.getItem('reveal');
    const [renderStuff, setRenderStuff] = useState(process.env.NODE_ENV === 'development' || window.location.search === '?reveal' || revealLS);

    const userData = data?.message === 'no auth' ? null : data;
    const loggedIn = data?.message === 'no auth' ? false : true;

    if (renderStuff && process.env.NODE_ENV !== 'development') {
        window.localStorage.setItem('reveal', 'yes');
    }

    (window as any).reveal = useCallback(() => {
        setRenderStuff(true);
    }, [
        setRenderStuff
    ]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    if (isLoading) {
        return <CircularProgress isIndeterminate size='120px' color='var(--primary-button)' position="absolute" top="100px" left="50%" transform="translateX(-50%)" />;
    }

    const LoggedInComponent = InComponent || OutComponent;
    const LoggedOutComponent = OutComponent || (() => <></>);
    const ComingSoonComponent = SoonComponent || ComingSoon;

    if (!renderStuff) {
        return (<>
            <ComingSoonNav />
            <ComingSoonComponent />
            <ComingSoonFoot />
        </>);
    }

    return (<>
        {loggedIn && <HeartBeat />}
        <TopNav loggedIn={loggedIn} userData={userData} />
        <Box mt="26px" minH={`calc(100vh - 260px)`}>
            <Suspense fallback={<CircularProgress isIndeterminate size='120px' color='var(--primary-button)' position="absolute" top="250px" left="50%" transform="translateX(-50%)" />}>
                {loggedIn && <LoggedInComponent userData={userData} />}
                {!loggedIn && <LoggedOutComponent userData={userData} />}
            </Suspense>
        </Box>
        <Footer />
    </>);
};

const loadGoogleAnalytics = (): void => {
    const script: HTMLScriptElement = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=YOUR_TRACKING_ID';
    script.async = true;
    document.head.appendChild(script);

    script.onload = (): void => {
        window.dataLayer = window.dataLayer || [];

        function gtag(...args: any[]): void {
            window.dataLayer.push(args);
        }

        gtag('js', new Date());
        gtag('config', 'G-EV0SBN6GRT');
    };
};

const CookieBannerWrapper = ({ children }: { children: ReactElement }) => {
    return <>
        <CookieConsent
            location="bottom"
            buttonText="Yes"
            declineButtonText="No"
            enableDeclineButton
            cookieName="chaptercatsCookieConsent"
            style={{ background: "#DFC9B9", color: "#242424" }}
            buttonStyle={{ background: "#6C5151", color: "white", borderRadius: 10, fontSize: "13px" }}
            declineButtonStyle={{ color: "#242424", border: "1px solid #6C5151", background: "#DFC9B9", borderRadius: 10, fontSize: "13px", marginRight: 0 }}
            expires={150}
            onAccept={() => {
                console.log("Cookies accepted.");
                // Add Google Analytics script after accepting cookies
                if (!getCookieConsentValue()) {
                    loadGoogleAnalytics();
                }
            }}
        >This site uses cookies. Are you ok with that?</CookieConsent>
        {children}
    </>;
};

const RedirectHome = ({ userData }: any) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (userData && userData.status === 'pending-approval') {
            navigate('/verify');
        } else {
            navigate('/');
        }
    }, [userData, navigate]);
    return null;
};

const router = createBrowserRouter([
    {
        path: "/",
        element: <CookieBannerWrapper><AuthWrapper InComponent={HomepageIn} OutComponent={HomepageOut} /></CookieBannerWrapper>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/affiliate-disclosure",
        element: <CookieBannerWrapper><AuthWrapper OutComponent={RedirectHome} InComponent={RedirectHome} SoonComponent={AffiliateDisclosure} /></CookieBannerWrapper>,
    },
    {
        path: "/blog/:id",
        element: <CookieBannerWrapper><AuthWrapper OutComponent={RedirectHome} InComponent={RedirectHome} SoonComponent={Blog} /></CookieBannerWrapper>,
    },
    {
        path: "/verify",
        element: <CookieBannerWrapper><AuthWrapper OutComponent={EmailVerify} InComponent={EmailVerify} /></CookieBannerWrapper>,
    },
    {
        path: "/startclub",
        element: <CookieBannerWrapper><AuthWrapper OutComponent={RedirectHome} InComponent={CreateClub} /></CookieBannerWrapper>,
    },
    {
        path: "/recommendation/:id",
        element: <CookieBannerWrapper><AuthWrapper OutComponent={RedirectHome} InComponent={RecommendDetails} /></CookieBannerWrapper>,
    },
    {
        path: "/Profile",
        element: <CookieBannerWrapper><AuthWrapper OutComponent={RedirectHome} InComponent={Profile} /></CookieBannerWrapper>,
    },
    {
        path: "/login",
        element: <CookieBannerWrapper><AuthWrapper OutComponent={Login} InComponent={RedirectHome} /></CookieBannerWrapper>,
    },
    {
        path: "/signup",
        element: <CookieBannerWrapper><AuthWrapper OutComponent={Signup} InComponent={RedirectHome} /></CookieBannerWrapper>,
    },
    {
        path: "/aboutus",
        element: <CookieBannerWrapper><AuthWrapper OutComponent={AboutUs} InComponent={AboutUs} /></CookieBannerWrapper>,
    },
    {
        path: "/chattest",
        element: <CookieBannerWrapper><AuthWrapper OutComponent={ChatTest} InComponent={ChatTest} /></CookieBannerWrapper>,
    },
    {
        path: "/data",
        element: <CookieBannerWrapper><AuthWrapper OutComponent={YourData} InComponent={YourData} /></CookieBannerWrapper>,
    },
    {
        path: "/privacy",
        element: <CookieBannerWrapper><AuthWrapper OutComponent={Privacy} InComponent={Privacy} SoonComponent={Privacy} /></CookieBannerWrapper>,
    },
    {
        path: "/terms",
        element: <CookieBannerWrapper><AuthWrapper OutComponent={Terms} InComponent={Terms} /></CookieBannerWrapper>,
    },
    {
        path: "/search",
        element: <CookieBannerWrapper><AuthWrapper OutComponent={SearchPage} InComponent={SearchPage} /></CookieBannerWrapper>,
    },
    // 404
    {
        path: "*",
        element: <CookieBannerWrapper><AuthWrapper OutComponent={NotFound} InComponent={NotFound} /></CookieBannerWrapper>,
    },
]);

export const Router = () => {
    return (
        <RouterProvider router={router} />
    );
};
