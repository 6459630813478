import { useEffect } from 'react';
import { FaGear, FaStar } from 'react-icons/fa6';
import { IconType } from 'react-icons';
import { useNavigate } from 'react-router-dom';
import { Text, Link, Box, Button, Image, Container, Heading, useDisclosure, Center, Flex, HStack, ButtonGroup, useMediaQuery, IconButton, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerOverlay, Icon, ListItem, List } from '@chakra-ui/react';
import { FaBars, FaDoorOpen, FaHome, FaCat, FaHandHoldingHeart, FaSearch } from 'react-icons/fa';

import { useLogout } from '../queries/user';
import { useIsBig } from '../utils/useIsBig';
import RecommendWizard from '../pages/RecommendWizard';
import logo from '../images/logo.png';

type NavItemParams = {
    icon: IconType;
    label: string;
    link: string;
    onClick?: (evt: { preventDefault: () => void; }) => void;
};

const NavItem = ({ icon, label, link, onClick = () => { } }: NavItemParams) => {
    const navigate = useNavigate();
    const onAction = (evt: { preventDefault: () => void; }) => {
        evt.preventDefault();
        navigate(link);
        onClick(evt);
    };
    return (
        <ListItem mt="5px">
            <Link
                display="block"
                as={Link}
                href={link}
                onClick={onAction}
                color="var(--primary-hover)"
                _focus={{ bg: "var(--secondary-hover)" }}
                _hover={{
                    bg: "var(--primary-button)",
                    color: "var(--white-chip)",
                }}
                _activeLink={{ bg: "var(--secondary-hover)" }}
                w="full"
                borderRadius={5}
            >
                <Flex alignItems="center" p={2}>
                    <Icon boxSize="5" as={icon} />
                    <Text size="subtitle1" ml={2}>{label}</Text>
                </Flex>
            </Link>
        </ListItem>
    );
}

export const TopNav = ({ loggedIn, userData }: any) => {
    const { isOpen, onToggle, onClose, onOpen } = useDisclosure();
    const [isLargerThan955] = useMediaQuery("(min-width: 955px)");
    const isBig = useIsBig();

    (window as any).openRecommend = () => window.location.hash = 'recommendation';
    (window as any).closeRecommend = () => window.location.hash = '';

    useEffect(() => {
        if (isOpen && isLargerThan955 && !loggedIn) {
            onClose();
        }
    }, [isOpen, onClose, isLargerThan955, loggedIn])

    const navigate = useNavigate();
    const { logout } = useLogout();

    const onSignOut = (evt: { preventDefault: () => void; }) => {
        onClose();
        evt.preventDefault();
        logout();
    };

    return (<>
        <Box className="header-guy" h={isBig ? '146px' : '100px'} borderBottom="1px solid var(--header-divider)" bg="var(--header-fill)" w="100%" pt="25px" color="var(--primary-text)" position="fixed" zIndex={10} shadow="0px 0px 12px 0px var(--disabled-text)">
            <Container maxW="1000px" p="0px 10px">
                <Flex minWidth='full' width="full" alignItems='center' border="1px sollid #333">
                    <HStack flexGrow={1}>
                        <Image src={logo} alt="Cat reading a book, symbolizing a community for book lovers to explore reviews, share recommendations, and discuss favorite books." h={isBig ? '97px' : '55px'} cursor="pointer" onClick={() => { navigate('/'); window.scrollTo(0, 0); }} />
                        <Box width="full" style={{ fontFamily: '"Trocchi", sans-serif', fontSize: isBig ? 40 : 25 }}>Chapter Cats</Box>
                    </HStack>
                    {loggedIn && <>
                        <HStack>
                            {isLargerThan955 && (<>
                                <Button variant="topnav" onClick={() => navigate('/search')}>Search</Button>
                                <Button variant="topnav" onClick={(window as any).openRecommend}>Get Recommendation</Button>
                                {/* <Button variant="topnav" onClick={() => navigate('/startclub')}>Clubs</Button> */}
                            </>)}

                            <Image
                                src={userData?.profile_picture_url || '/basic_avatars/unknown.png'}
                                cursor="pointer"
                                width="64px"
                                height="64px"
                                borderRadius="lg"
                                boxShadow="2px 2px 10px -6px rgba(0,0,0,0.75)"
                                ml="20px"
                                onClick={onToggle}
                            />
                            <Drawer onClose={onClose} isOpen={isOpen} size="xs">
                                <DrawerOverlay />
                                <DrawerContent backgroundColor="var(--primary-background)">
                                    <DrawerCloseButton />
                                    <DrawerBody>
                                        <Box textAlign="left" mt="30px">
                                            <Flex direction="column">
                                                <Center>
                                                    <Image
                                                        src={userData?.profile_picture_url || '/basic_avatars/unknown.png'}
                                                        width="128px"
                                                        height="128px"
                                                        borderRadius="lg"
                                                        boxShadow="2px 2px 10px -6px rgba(0,0,0,0.75)"
                                                        ml="20px"
                                                    />
                                                </Center>
                                                    <Center>
                                                        <Heading size="md" my={3}>
                                                        {userData.name || ' '}
                                                        </Heading>
                                                    </Center>
                                                <List>
                                                    <NavItem onClick={(evt) => onClose()} link="/" icon={FaHome} label="Home" />
                                                    <NavItem onClick={(evt) => onClose()} link="/search" icon={FaSearch} label="Search" />
                                                    <NavItem onClick={(evt) => {
                                                        onClose();
                                                        (window as any).openRecommend();
                                                    }} link="#" icon={FaHandHoldingHeart} label="Get Recommendation" />
                                                    {/* <NavItem onClick={(evt) => onClose()} link="/startclub" icon={FaUsers} label="Clubs" /> */}
                                                    {userData.status === 'pending-approval' && (
                                                    <NavItem onClick={(evt) => onClose()} link="/verify" icon={FaStar} label="Verify Account" />
                                                    )}
                                                    <NavItem onClick={(evt) => onClose()} link="/profile" icon={FaGear} label="Settings" />
                                                    <NavItem onClick={onSignOut} link="/" icon={FaDoorOpen} label="Sign Out" />
                                                </List>
                                            </Flex>
                                        </Box>
                                    </DrawerBody>
                                </DrawerContent>
                            </Drawer>
                        </HStack>
                    </>}
                    {!loggedIn && <>
                        {isLargerThan955 && (
                            <ButtonGroup>
                                <Button variant="topnav" onClick={() => navigate('/search')}>Search</Button>
                                <Button variant="topnav" onClick={(window as any).openRecommend}>Get Started</Button>
                                <Button variant="topnav" onClick={() => navigate('/aboutus')}>About Us</Button>
                                <Button variant="topnav" onClick={() => navigate('/login')}>Login</Button>
                            </ButtonGroup>
                        )}
                        {!isLargerThan955 && (<>
                            <IconButton variant="topnav" onClick={isOpen ? onClose : onOpen} aria-label='Site Navigation' icon={<FaBars size="30px" />} />
                            <Drawer onClose={onClose} isOpen={isOpen} size="sm">
                                <DrawerOverlay />
                                <DrawerContent>
                                    <DrawerCloseButton />
                                    <DrawerBody>
                                        <Box textAlign="left" mt="30px">
                                            <Center>
                                                <Image src={logo} alt="logo" h={isBig ? '97px' : '75px'} />
                                            </Center>
                                            <Center mb="10px">
                                                <Box style={{ fontFamily: '"Trocchi", sans-serif', fontSize: 20 }}>Chapter Cats</Box>
                                            </Center>
                                            <hr />
                                            <List mt="10px">
                                                <NavItem onClick={(evt) => onClose()} link="/" icon={FaHome} label="Home" />
                                                <NavItem onClick={(evt) => onClose()} link="/search" icon={FaSearch} label="Search" />
                                                <NavItem onClick={(evt) => onClose()} link="/aboutus" icon={FaCat} label="About Us" />
                                                <NavItem onClick={(evt) => {
                                                    onClose();
                                                    (window as any).openRecommend();
                                                }} link="#" icon={FaHandHoldingHeart} label="Get Started" />
                                                <NavItem onClick={(evt) => onClose()} link="/login" icon={FaDoorOpen} label="Login" />
                                            </List>
                                        </Box>
                                    </DrawerBody>
                                </DrawerContent>
                            </Drawer>
                        </>)}
                    </>}
                </Flex>
            </Container>
        </Box >
        <Box h={isBig ? '120px' : '74px'}></Box>
        <RecommendWizard userData={userData} />
    </>);
}