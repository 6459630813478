import { Box, Wrap, WrapItem, useCheckboxGroup, Button, Icon, Input, IconButton, InputGroup, InputRightElement, Heading } from '@chakra-ui/react';
import { AddIcon, CheckIcon } from '@chakra-ui/icons';
import { useEffect, useState } from 'react';

type BubblePickerParams = { itemList: any[], selected: string[], onChange: Function, title: string, includeOther: boolean, sortItems: boolean, noPref: boolean };

export const BubblePicker = ({ itemList, selected, onChange, title, includeOther, sortItems, noPref }: BubblePickerParams) => {
    const { value: selectedValues, getCheckboxProps, setValue } = useCheckboxGroup({
        onChange: (selectedItems) => {
            onChange(selectedItems);
        },
    });
    const [inputValue, setInputValue] = useState('');
    const [inputMode, setInputMode] = useState(false);

    useEffect(() => {
        if ([...(selected || [])].sort().join('-') !== [...selectedValues].sort().join('-')) {
            setValue(selected || []);
        }
    }, [selected, selectedValues, setValue]);

    const handleCheckboxChange = (value: string) => {
        if (noPref) {
            if (value === 'No Preference' && !selectedValues.includes('No Preference')) {
                setValue(['No Preference']);
                return;
            }
            if (value !== 'No Preference' && selectedValues.includes('No Preference')) {
                setValue([...selectedValues, value].filter((itemValue) => itemValue !== 'No Preference'))
                return;
            }
        }

        const newSelectedValues = selectedValues.includes(value)
            ? selectedValues.filter((itemValue) => itemValue !== value)
            : [...selectedValues, value];
        setValue(newSelectedValues);
    };

    const allItems = [...(itemList || [])];
    if (noPref) {
        allItems.push({
            id: 'no-pref',
            title: 'No Preference',
        })
    }
    (selected || []).forEach(item => {
        if (!allItems.find((i) => i.title === item)) {
            allItems.push({
                id: item,
                title: item,
            });
        }
    });

    if (sortItems) {
        allItems.sort((a, b) => a.title < b.title ? -1 : 1);
    }

    return (
        <Box py={4}>
            <Heading size="h3" mb="20px">{title}</Heading>
            <Wrap spacing={4} justify="left">
                {allItems.map((item) => {
                    const checkboxProps = getCheckboxProps({ value: item.title });
                    return (
                        <WrapItem key={item.title}>
                            <Button
                                {...(checkboxProps as any)}
                                onClick={() => handleCheckboxChange(item.title)}
                                size="md"
                                variant="primary"
                                padding="2"
                                pl="20px"
                                pr="20px"
                                borderRadius="full"
                                // borderWidth="1px"
                                boxShadow="2px 2px 10px -6px rgba(0,0,0,0.75)"
                                color={selectedValues.includes(item.title) ? "var(--white-chip)" : "var(--primary-button)"}
                                bg={selectedValues.includes(item.title) ? "var(--primary-button)" : "var(--white-chip)"}
                                _active={{
                                    bg: selectedValues.includes(item.title) ? "var(--primary-button)" : "inherit",
                                }}
                                _hover={{
                                    bg: selectedValues.includes(item.title) ? "var(--primary-hover)" : "var(--secondary-hover)",
                                }}
                                leftIcon={
                                    selectedValues.includes(item.title) ? (
                                        <Icon as={CheckIcon} />
                                    ) : (
                                        <Icon as={AddIcon} />
                                    )
                                }
                            >
                                {item.title}
                            </Button>
                        </WrapItem>
                    );
                })}
                {includeOther && (
                    <WrapItem>
                        {inputMode && <>
                            <InputGroup size="md" mb="20px">
                                <Input
                                    maxLength={30}
                                    name="new-user-password"
                                    autoComplete="off"
                                    autoFocus={true}
                                    onBlur={() => {
                                        if (inputValue.trim() === '') {
                                            setInputMode(false);
                                        }
                                    }}
                                    value={inputValue}
                                    onKeyUp={(evt) => {
                                        if (evt.key === 'Enter' && inputValue.trim() !== '') {
                                            if (!selectedValues.includes(inputValue)) {
                                                handleCheckboxChange(inputValue);
                                                setInputMode(false);
                                                setInputValue('');
                                            }
                                        }
                                    }}
                                    onChange={(evt) => setInputValue(evt.target.value)}
                                />
                                <InputRightElement>
                                    <IconButton onClick={() => {
                                        if (inputValue.trim() !== '') {
                                            if (!selectedValues.includes(inputValue)) {
                                                handleCheckboxChange(inputValue);
                                                setInputMode(false);
                                                setInputValue('');
                                            }
                                        }
                                    }} aria-label='Show password' icon={<AddIcon />} />
                                </InputRightElement>
                            </InputGroup>
                        </>}
                        {!inputMode && <Button
                            // {...(checkboxProps as any)}
                            onClick={() => setInputMode(true)}
                            size="md"
                            variant="primary"
                            color="var(--primary-button)"
                            padding="2"
                            pl="20px"
                            pr="20px"
                            borderRadius="full"
                            borderWidth="1px"
                            borderColor="gray.300"
                            boxShadow="2px 2px 10px -6px rgba(0,0,0,0.75)"
                            bg="white"
                            _active={"inherit" as any}
                            _hover={"var(--secondary-hover)" as any}
                            leftIcon={<Icon as={AddIcon} />}
                        >
                            Other...
                        </Button>}
                    </WrapItem>
                )}
            </Wrap>
        </Box>
    );
};
