import {
    Image,
    Box,
    Heading,
    Stack,
    Text,
    HStack,
    useMediaQuery,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export const BlogCardComingSoon = ({ blog }: { blog: any }) => {
    const [isLargerThan750] = useMediaQuery("(min-width: 750px)");
    const navigate = useNavigate();

    if (isLargerThan750) {
        return (
            <Box onClick={() => { navigate(`/blog/${blog.id}`) }} rounded="xl" overflow="hidden" boxShadow="2px 2px 10px -6px rgba(0,0,0,0.75)" width="100%" mb="50px" cursor="pointer">
                <Box p="30px">
                    <HStack spacing="20px" mb="30px">
                        <Image
                            boxShadow="2px 2px 10px -6px rgba(0,0,0,0.75)"
                            alt="Login Image"
                            width="200px"
                            src={blog.imageLink}
                        />
                        <Stack spacing={4} w="full">
                            <Text>{blog.date}&nbsp;&nbsp;|&nbsp;&nbsp;5 minute read</Text>
                            <Heading size="h5">{blog.title}</Heading>
                        </Stack>
                    </HStack>
                    <Text size="sm">{blog.intro}</Text>
                </Box>
            </Box>
        );
    }
    return (
        <Box onClick={() => { navigate(`/blog/${blog.id}`) }} rounded="xl" overflow="hidden" boxShadow="2px 2px 10px -6px rgba(0,0,0,0.75)" width="100%" mb="50px" cursor="pointer">
            <Image
                boxShadow="2px 2px 10px -6px rgba(0,0,0,0.75)"
                alt={blog.bookTitle}
                width="full"
                src={blog.imageLink}
            />
            <Box p="30px">
                <HStack spacing="20px" mb="30px">
                    <Stack spacing={4} w="full">
                        <Text>{blog.date}&nbsp;&nbsp;|&nbsp;&nbsp;5 minute read</Text>
                        <Heading size="h5">{blog.title}</Heading>
                    </Stack>
                </HStack>
                <Text size="sm">{blog.intro}</Text>
            </Box>
        </Box>
    );
}