import { Container, Box, Heading, Text, Link } from '@chakra-ui/react';

function YourData(): JSX.Element {
    return (
        <><Container mb="20px" maxW="1000px" mt="80px">
            <Box maxW="800px" mx="auto" p={6}>
                <Heading as="h1" size="xl" mb={4}>
                    Affiliate Disclosure
                </Heading>

                <Text mb="300px">
                    <p>
                        As an Amazon Associate, I earn from qualifying purchases. This means that if you click on an Amazon link on this site and make a purchase, I may receive a small commission at no additional cost to you. This helps support the website and allows me to continue to provide content for you. Thank you for your support!
                    </p><br />
                    <p>
                        For more information about the Amazon Associates Program and its terms and conditions, please visit the <Link href="https://affiliate-program.amazon.com/help/operating/agreement" target="_blank">Amazon Associates Program Operating Agreement</Link>.
                    </p>
                </Text>
            </Box>
        </Container>
        </>
    );
}

export default YourData;
