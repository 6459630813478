import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

// Define the function to call your heartbeat endpoint
const fetchHeartbeat = async () => {
    return axios.post('/api/users/heartbeat', {
        body: '',
    });
};

export function useHeartbeat() {
    return useQuery({
        queryKey: ['heartbeat'],
        queryFn: fetchHeartbeat,
        retry: 0,
        refetchInterval: 5 * 60 * 1000, // refetch every 5 mins
    })
};

