import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const primary = defineStyle({
    borderRadius: 10,
    fontFamily: '"Montserrat", sans-serif',
    fontWeight: 'normal',
    fontStyle: 'normal',
    backgroundColor: 'var(--primary-button)',
    color: 'var(--white-chip)',
    _hover: {
        bg: 'var(--primary-hover)',
    },
    _disabled: {
        bg: 'var(--disabled-fill)',
        color: 'var(--disabled-text)',
    },
});
const topnav = defineStyle({
    fontFamily: '"Montserrat", sans-serif',
    fontWeight: '600',
    fontStyle: 'normal',
    backgroundColor: 'transparent',
    color: 'var(--primary-button)',
    _hover: {
        color: 'var(--primary-hover)'
    },
});
const secondary = defineStyle({
    fontFamily: '"Montserrat", sans-serif',
    fontWeight: '600',
    fontStyle: 'normal',
    backgroundColor: 'transparent',
    color: 'var(--primary-button)',
    _hover: {
        color: 'var(--primary-hover)',
        backgroundColor: 'var(--secondary-hover)'
    },
    border: '1px solid var(--primary-button)'
});

export const buttonTheme = defineStyleConfig({
    baseStyle: {
        fontWeight: '600',
        borderRadius: 10,
    },
    // Two sizes: sm and md
    sizes: {
        sm: {
            fontSize: 'sm',
            px: 4, // <-- px is short for paddingLeft and paddingRight
            py: 3, // <-- py is short for paddingTop and paddingBottom
        },
        md: {
            fontSize: 'md',
            px: 6, // <-- these values are tokens from the design system
            py: 4, // <-- these values are tokens from the design system
        },
    },
    variants: {
        primary,
        topnav,
        secondary,
    },
    // The default size and variant values
    defaultProps: {
        size: 'md',
        variant: 'primary',
    },
})