export const genresAndAuthors = [
    {
        "id": "fiction",
        "title": "Fiction",
        "authors": [
            {
                id: 'ernest-hemingway',
                title: 'Ernest Hemingway',
            },
            {
                id: 'lis-see',
                title: 'Lisa See',
            },
            {
                id: 'haruki-murakami',
                title: 'Haruki Murakami',
            },
            {
                id: 'agatha-christie',
                title: 'Agatha Christie',
            },
            {
                id: 'jane-austen',
                title: 'Jane Austen',
            },
            {
                id: 'cormac-mccarthy',
                title: 'Cormac McCarthy'
            },
        ],
        "subGenres": [
            {
                "id": "historical-fiction",
                "title": 'Historical Fiction',
                "authors": [
                    {
                        id: 'hillary-mantel',
                        title: 'Hilary Mantel',
                    },
                    {
                        id: 'ken-follett',
                        title: 'Ken Follett',
                    },
                    {
                        id: 'philippa-gregory',
                        title: 'Philippa Gregory',
                    },
                    {
                        id: 'bernard-cornwell',
                        title: 'Bernard Cornwell',
                    },
                    {
                        id: 'lis-see',
                        title: 'Lisa See',
                    },
                ],
            },
            {
                "id": "literary-fiction",
                "title": 'Literary Fiction',
                "authors": [
                    {
                        id: 'toni-morrison',
                        title: 'Toni Morrison',

                    },
                    {
                        id: 'haruki-murakami',
                        title: 'Haruki Murakami',
                    },
                    {
                        id: 'kazuo-ishiguro',
                        title: 'Kazuo Ishiguro',
                    },
                    {
                        id: 'jonathan-franzen',
                        title: 'Jonathan Franzen',
                    },
                    {
                        id: 'ian-mcewan',
                        title: 'Ian McEwan',
                    },
                ],
            },
            {
                "id": "mystery-thriller",
                "title": 'Mystery/Thriller',
                "authors": [
                    {
                        id: 'agatha-christie',
                        title: 'Agatha Christie',
                    },
                    {
                        id: 'arthor-conan-doyle',
                        title: 'Arthur Conan Doyle',
                    },
                    {
                        id: 'lee-child',
                        title: 'Lee Child',
                    },
                    {
                        id: 'patricia-highsmith',
                        title: 'Patricia Highsmith',
                    },
                    {
                        id: 'michael-connelly',
                        title: 'Michael Connelly',
                    },
                    {
                        id: 'stieg-larsson',
                        title: 'Stieg Larsson',
                    },
                ]
            },
            {
                "id": "romance",
                "title": 'Romance',
                "authors": [
                    {
                        id: 'jane-austen',
                        title: 'Jane Austen',
                    },
                    {
                        id: 'nora-roberts',
                        title: 'Nora Roberts',
                    },
                    {
                        id: 'danilelle-steel',
                        title: 'Danielle Steel',
                    },
                    {
                        id: 'beverly-jenkins',
                        title: 'Beverly Jenkins',
                    },
                    {
                        id: 'lisa-kleypas',
                        title: 'Lisa Kleypas',
                    },
                ]
            },
            {
                "id": "western",
                "title": "Western",
                "authors": [
                    {
                        id: 'loiuse-lamour',
                        title: 'Louis L\'Amour'
                    },
                    {
                        id: 'larry-mcmurtry',
                        title: 'Larry McMurtry'
                    },
                    {
                        id: 'cormac-mccarthy',
                        title: 'Cormac McCarthy'
                    },
                    {
                        id: 'dorothy-johnson',
                        title: 'Dorothy M. Johnson'
                    },
                    {
                        id: 'elmor-kelton',
                        title: 'Elmer Kelton'
                    },
                ]
            },
            {
                "id": "adventure",
                "title": 'Adventure',
                "authors": [
                    {
                        id: 'jules-verne',
                        title: 'Jules Verne',
                    },
                    {
                        id: 'rob-louis-stevenson',
                        title: 'Robert Louis Stevenson',
                    },
                    {
                        id: 'jack-london',
                        title: 'Jack London',
                    },
                    {
                        id: 'arthur-conan-doyle',
                        title: 'Arthur Conan Doyle',
                    },
                    {
                        id: 'rudyard-kipling',
                        title: 'Rudyard Kipling',
                    },
                    {
                        id: 'ernest-hemingway',
                        title: 'Ernest Hemingway',
                    },
                ]
            }
        ],
    },
    {
        "id": "non-fiction",
        "title": "Non-Fiction",
        "authors": [
            {
                id: 'mary-beard',
                title: 'Mary Beard',
            },
            {
                id: 'neil-degrasse-tyson',
                title: 'Neil deGrasse Tyson',
            },
            {
                id: 'marie-kondo',
                title: 'Marie Kondo',
            },
            {
                id: 'aphrodite-jones',
                title: 'Aphrodite Jones',
            },
            {
                id: 'michael-pollan',
                title: 'Michael Pollan',
            },
            {
                id: 'karen-armstrong',
                title: 'Karen Armstrong',
            },
        ],
        "subGenres": [
            {
                "id": "history",
                "title": 'History',
                "authors": [
                    {
                        id: 'doris-kearns-goodwin',
                        title: 'Doris Kearns Goodwin',
                    },
                    {
                        id: 'david-mccullough',
                        title: 'David McCullough',
                    },
                    {
                        id: 'ron-chernow',
                        title: 'Ron Chernow',
                    },
                    {
                        id: 'mary-beard',
                        title: 'Mary Beard',
                    },
                    {
                        id: 'niaill-ferguson',
                        title: 'Niall Ferguson',
                    },
                ]
            },
            {
                "id": "science-technology",
                "title": 'Science & Technology',
                "authors": [
                    {
                        id: 'stephen-hawking',
                        title: 'Stephen Hawking',
                    },
                    {
                        id: 'carl-sagan',
                        title: 'Carl Sagan',
                    },
                    {
                        id: 'neil-degrasse-tyson',
                        title: 'Neil deGrasse Tyson',
                    },
                    {
                        id: 'michio-kaku',
                        title: 'Michio Kaku',
                    },
                    {
                        id: 'jane-goodall',
                        title: 'Jane Goodall',
                    },
                    {
                        id: 'steven-pinker',
                        title: 'Steven Pinker',
                    },
                ]
            },
            {
                "id": "self-help",
                "title": 'Self-Help/Personal Development',
                "authors": [
                    {
                        id: 'dale-carnegie',
                        title: 'Dale Carnegie',
                    },
                    {
                        id: 'brene-brown',
                        title: 'Brené Brown',
                    },
                    {
                        id: 'tony-robbins',
                        title: 'Tony Robbins',
                    },
                    {
                        id: 'stephen-r-covey',
                        title: 'Stephen R. Covey',
                    },
                    {
                        id: 'susan-cain',
                        title: 'Susan Cain',
                    },
                    {
                        id: 'marie-kondo',
                        title: 'Marie Kondo',
                    },
                ]
            },
            {
                "id": "true-crime",
                "title": 'True Crime',
                "authors": [
                    {
                        id: 'ann-rule',
                        title: 'Ann Rule',
                    },
                    {
                        id: 'john-grisham',
                        title: 'John Grisham',
                    },
                    {
                        id: 'truman-capote',
                        title: 'Truman Capote',
                    },
                    {
                        id: 'jon-krakauer',
                        title: 'Jon Krakauer',
                    },
                    {
                        id: 'aphrodite-jones',
                        title: 'Aphrodite Jones',
                    },
                ]
            },
            {
                "id": "health-wellness",
                "title": 'Health & Wellness',
                "authors": [
                    {
                        id: 'atul-gawande',
                        title: 'Atul Gawande',
                    },
                    {
                        id: 'michael-pollan',
                        title: 'Michael Pollan',
                    },
                    {
                        id: 'andrew-weil',
                        title: 'Andrew Weil',
                    },
                    {
                        id: 'brene-brown',
                        title: 'Brené Brown',
                    },
                    {
                        id: 'steven-hayes',
                        title: 'Steven Hayes',
                    },
                ]
            },
            {
                "id": "religion-spirituality",
                "title": 'Religion & Spirituality',
                "authors": [
                    {
                        id: 'dalai-lama',
                        title: 'Dalai Lama',
                    },
                    {
                        id: 'karen-armstrong',
                        title: 'Karen Armstrong',
                    },
                    {
                        id: 'deepak-chopra',
                        title: 'Deepak Chopra',
                    },
                    {
                        id: 'cs-lewis',
                        title: 'C.S. Lewis',
                    },
                    {
                        id: 'rez-aslan',
                        title: 'Reza Aslan',
                    },
                    {
                        id: 'ram-dass',
                        title: 'Ram Dass',
                    },
                ]
            },
        ]
    },
    {
        "id": "science-fiction",
        "title": "Sci-Fi",
        "authors": [
            {
                id: 'larry-niven',
                title: 'Larry Niven',
            },
            {
                id: 'frank-herbert',
                title: 'Frank Herbert',
            },
            {
                id: 'ursula-k-leguin',
                title: 'Ursula K. Le Guin',
            },
            {
                id: 'neal-stephenson',
                title: 'Neal Stephenson',
            },
            {
                id: 'hg-wells',
                title: 'H.G. Wells',
            },
            {
                id: 'octavia-e-butler',
                title: 'Octavia E. Butler',
            },
        ],
        "subGenres": [
            {
                "id": "hard-science-fiction",
                "title": 'Hard Science Fiction',
                "authors": [
                    {
                        id: 'arthur-c-clarke',
                        title: 'Arthur C. Clarke',
                    },
                    {
                        id: 'isaac-asimov',
                        title: 'Isaac Asimov',
                    },
                    {
                        id: 'greg-bear',
                        title: 'Greg Bear',
                    },
                    {
                        id: 'larry-niven',
                        title: 'Larry Niven',
                    },
                    {
                        id: 'alastair-reynolds',
                        title: 'Alastair Reynolds',
                    },
                ]
            },
            {
                "id": "space-opera",
                "title": 'Space Opera',
                "authors": [
                    {
                        id: 'frank-herbert',
                        title: 'Frank Herbert',
                    },
                    {
                        id: 'iain-m-banks',
                        title: 'Iain M. Banks',
                    },
                    {
                        id: 'lois-mcmaster-bujold',
                        title: 'Lois McMaster Bujold',
                    },
                    {
                        id: 'peter-f-hamilton',
                        title: 'Peter F. Hamilton',
                    },
                    {
                        id: 'james-sa-corey',
                        title: 'James S.A. Corey',
                    },
                ]
            },
            {
                "id": "dystopian-utopian",
                "title": 'Dystopian / Utopian',
                "authors": [
                    {
                        id: 'george-orwell',
                        title: 'George Orwell',
                    },
                    {
                        id: 'margaret-atwood',
                        title: 'Margaret Atwood',
                    },
                    {
                        id: 'philip-k-dick',
                        title: 'Philip K. Dick',
                    },
                    {
                        id: 'ursula-k-leguin',
                        title: 'Ursula K. Le Guin',
                    },
                    {
                        id: 'edward-bellamy',
                        title: 'Edward Bellamy',
                    },
                ],
            },
            {
                "id": "cyberpunk",
                "title": 'Cyberpunk',
                "authors": [
                    {
                        id: 'william-gibson',
                        title: 'William Gibson',
                    },
                    {
                        id: 'neal-stephenson',
                        title: 'Neal Stephenson',
                    },
                    {
                        id: 'pat-cadigan',
                        title: 'Pat Cadigan',
                    },
                    {
                        id: 'john-shirley',
                        title: 'John Shirley',
                    },
                    {
                        id: 'richard-k-morgan',
                        title: 'Richard K. Morgan',
                    },
                ],
            },
            {
                "id": "time-travel",
                "title": 'Time Travel',
                "authors": [
                    {
                        id: 'hg-wells',
                        title: 'H.G. Wells',
                    },
                    {
                        id: 'robert-a-heinlein',
                        title: 'Robert A. Heinlein',
                    },
                    {
                        id: 'audrey-niffenegger',
                        title: 'Audrey Niffenegger',
                    },
                    {
                        id: 'jack-finney',
                        title: 'Jack Finney',
                    },
                ],
            },
            {
                "id": "speculative-science-fiction",
                "title": 'Speculative Science Fiction',
                "authors": [
                    {
                        id: 'china-mieville',
                        title: 'China Miéville',
                    },
                    {
                        id: 'kim-stanley-robinson',
                        title: 'Kim Stanley Robinson',
                    },
                    {
                        id: 'octavia-e-butler',
                        title: 'Octavia E. Butler',
                    },
                    {
                        id: 'ann-leckie',
                        title: 'Ann Leckie',
                    },
                ],
            },
        ],
    },
    {
        "id": "fantasy",
        "title": "Fantasy",
        "authors": [
            {
                id: "terry-pratchett",
                title: "Terry Pratchett",
            },
            {
                id: 'george-rr-martin',
                title: 'George R.R. Martin',
            },
            {
                id: 'neil-gaiman',
                title: 'Neil Gaiman',
            },
            {
                id: 'tanith-lee',
                title: 'Tanith Lee',
            },
            {
                id: 'garbriel-garcia-marquez',
                title: 'Gabriel García Márquez',
            },
            {
                id: 'fritz-leiber',
                title: 'Fritz Leiber',
            },
            {
                id: 'mark-tompkins',
                title: 'Mark Tompkins',
            },
        ],
        "subGenres": [
            {
                "id": "comedic-fantasy",
                "title": "Comedic Fantasy",
                "authors": [
                    {
                        id: "terry-pratchett",
                        title: "Terry Pratchett",
                    },
                    {
                        id: "piers-anthony",
                        title: "Piers Anthony",
                    },
                    {
                        id: "jasper-fforde",
                        title: "Jasper Fforde",
                    },
                    {
                        id: "thorne-smith",
                        title: "Thorne Smith",
                    },
                ],
            },
            {
                "id": "high-fantasy",
                "title": 'High Fantasy',
                "authors": [
                    {
                        id: 'jrr-tolkien',
                        title: 'J.R.R. Tolkien',
                    },
                    {
                        id: 'george-rr-martin',
                        title: 'George R.R. Martin',
                    },
                    {
                        id: 'terry-goodkind',
                        title: 'Terry Goodkind',
                    },
                    {
                        id: 'terry-brooks',
                        title: 'Terry Brooks',
                    },
                    {
                        id: 'philip-pullman',
                        title: 'Philip Pullman',
                    },
                ]
            },
            {
                "id": "urban-fantasy",
                "title": 'Urban Fantasy',
                "authors": [
                    {
                        id: 'neil-gaiman',
                        title: 'Neil Gaiman',
                    },
                    {
                        id: 'jim-butcher',
                        title: 'Jim Butcher',
                    },
                    {
                        id: 'patricia-briggs',
                        title: 'Patricia Briggs',
                    },
                    {
                        id: 'charlaine-harris',
                        title: 'Charlaine Harris',
                    },
                    {
                        id: 'cassandra-clare',
                        title: 'Cassandra Clare',
                    },
                ]
            },
            {
                "id": "dark-fantasy",
                "title": 'Dark Fantasy',
                "authors": [
                    {
                        id: 'glen-cook',
                        title: 'Glen Cook',
                    },
                    {
                        id: 'joe-abercrombie',
                        title: 'Joe Abercrombie',
                    },
                    {
                        id: 'robin-hobb',
                        title: 'Robin Hobb',
                    },
                    {
                        id: 'tanith-lee',
                        title: 'Tanith Lee',
                    },
                    {
                        id: 'peter-v-brett',
                        title: 'Peter V. Brett',
                    },
                ]
            },
            {
                "id": "magical-realism",
                "title": 'Magical Realism',
                "authors": [
                    {
                        id: 'garbriel-garcia-marquez',
                        title: 'Gabriel García Márquez',
                    },
                    {
                        id: 'isabel-allende',
                        title: 'Isabel Allende',
                    },
                    {
                        id: 'salman-rushdie',
                        title: 'Salman Rushdie',
                    },
                    {
                        id: 'toni-morrison',
                        title: 'Toni Morrison',
                    },
                    {
                        id: 'haruki-murakami',
                        title: 'Haruki Murakami',
                    },
                    {
                        id: 'alice-hoffman',
                        title: 'Alice Hoffman',
                    },
                ]
            },
            {
                "id": "sword-and-sorcery",
                "title": 'Sword and Sorcery',
                "authors": [
                    {
                        id: 'robert-e-howard',
                        title: 'Robert E. Howard',
                    },
                    {
                        id: 'fritz-leiber',
                        title: 'Fritz Leiber',
                    },
                    {
                        id: 'michael-moorcock',
                        title: 'Michael Moorcock',
                    },
                    {
                        id: 'andrezj-sapkowski',
                        title: 'Andrzej Sapkowski',
                    },
                    {
                        id: 'karl-edward-wagner',
                        title: 'Karl Edward Wagner',
                    },
                ]
            },
            {
                "id": "historical-fantasy",
                "title": 'Historical Fantasy',
                "authors": [
                    {
                        id: 'susanna-clarke',
                        title: 'Susanna Clarke',
                    },
                    {
                        id: 'naomi-novik',
                        title: 'Naomi Novik',
                    },
                    {
                        id: 'mark-tompkins',
                        title: 'Mark Tompkins',
                    },
                    {
                        id: 'mary-robinette-kowal',
                        title: 'Mary Robinette Kowal',
                    },
                    {
                        id: 'bernard-cornwell',
                        title: 'Bernard Cornwell',
                    },
                ]
            },
        ],
    },
    {
        "id": "horror",
        "title": "Horror",
        "authors": [
            {
                id: 'anne-rice',
                title: 'Anne Rice',
            },
            {
                id: 'stephen-king',
                title: 'Stephen King',
            },
            {
                id: 'shirley-jackson',
                title: 'Shirley Jackson',
            },
            {
                id: 'peter-straub',
                title: 'Peter Straub',
            },
        ],
        "subGenres": [
            {
                "id": "gothic-horror",
                "title": 'Gothic Horror',
                "authors": [
                    {
                        id: 'anne-rice',
                        title: 'Anne Rice',
                    },
                    {
                        id: 'mary-shelley',
                        title: 'Mary Shelley',
                    },
                    {
                        id: 'horace-walpole',
                        title: 'Horace Walpole',
                    },
                    {
                        id: 'nathanial-hawthorne',
                        title: 'Nathaniel Hawthorne',
                    },
                    {
                        id: 'matthew-gregory-lewis',
                        title: 'Matthew Gregory Lewis',
                    },
                ]
            },
            {
                "id": "supernatural-horror",
                "title": 'Supernatural Horror',
                "authors": [
                    {
                        id: 'hp-lovecraft',
                        title: 'H.P. Lovecraft',
                    },
                    {
                        id: 'stephen-king',
                        title: 'Stephen King',
                    },
                    {
                        id: 'peter-straub',
                        title: 'Peter Straub',
                    },
                    {
                        id: 'clive-barker',
                        title: 'Clive Barker',
                    },
                    {
                        id: 'dean-koontz',
                        title: 'Dean Koontz',
                    },
                ]
            },
            {
                "id": "psychological-horror",
                "title": 'Psychological Horror',
                "authors": [
                    {
                        id: 'shirley-jackson',
                        title: 'Shirley Jackson',
                    },
                    {
                        id: 'thomas-harris',
                        title: 'Thomas Harris',
                    },
                    {
                        id: 'patrick-mcgrath',
                        title: 'Patrick McGrath',
                    },
                    {
                        id: 'ira-levin',
                        title: 'Ira Levin',
                    },
                    {
                        id: 'robert-aickman',
                        title: 'Robert Aickman',
                    },
                ]
            },
            {
                "id": "paranormal-horror",
                "title": 'Paranormal Horror',
                "authors": [
                    {
                        id: 'james-wan',
                        title: 'James Wan',
                    },
                    {
                        id: 'peter-straub',
                        title: 'Peter Straub',
                    },
                    {
                        id: 'joe-hill',
                        title: 'Joe Hill',
                    },
                    {
                        id: 'shirlry-jackson',
                        title: 'Shirley Jackson',
                    },
                    {
                        id: 'john-bellairs',
                        title: 'John Bellairs',
                    },
                ]
            }
        ],
    },
    {
        "title": "Drama",
        "authors": [
            {
                id: 'colleen-mccullough',
                title: 'Colleen McCullough',
            },
            {
                id: 'agatha-christie',
                title: 'Agatha Christie',
            },
            {
                id: 'george-orwell',
                title: 'George Orwell',
            },
            {
                id: 'richard-preston',
                title: 'Richard Preston',
            },
            {
                id: 'judy-blume',
                title: 'Judy Blume',
            },
        ],
        "subGenres": [
            {
                "id": "historical-drama",
                "title": 'Historical Drama',
                "authors": [
                    {
                        id: 'hilary-mantel',
                        title: 'Hilary Mantel',
                    },
                    {
                        id: 'ken-follett',
                        title: 'Ken Follett',
                    },
                    {
                        id: 'colleen-mccullough',
                        title: 'Colleen McCullough',
                    },
                    {
                        id: 'tracy-chevalier',
                        title: 'Tracy Chevalier',
                    },
                    {
                        id: 'james-clavell',
                        title: 'James Clavell',
                    },
                ]
            },
            {
                "id": "crime-drama",
                "title": 'Crime Drama',
                "authors": [
                    {
                        id: 'agatha-christie',
                        title: 'Agatha Christie',
                    },
                    {
                        id: 'dashiel-hammett',
                        title: 'Dashiell Hammett',
                    },
                    {
                        id: 'arthur-conan-doyle',
                        title: 'Arthur Conan Doyle',
                    },
                    {
                        id: 'tana-french',
                        title: 'Tana French',
                    },
                    {
                        id: 'michael-connelly',
                        title: 'Michael Connelly',
                    },
                ]
            },
            {
                "id": "political-drama",
                "title": 'Political Drama',
                "authors": [
                    {
                        id: 'george-orwell',
                        title: 'George Orwell',
                    },
                    {
                        id: 'tom-clancy',
                        title: 'Tom Clancy',
                    },
                    {
                        id: 'robert-harris',
                        title: 'Robert Harris',
                    },
                    {
                        id: 'hilary-mantel',
                        title: 'Hilary Mantel',
                    },
                    {
                        id: 'gore-vidal',
                        title: 'Gore Vidal',
                    },
                ]
            },
            {
                "id": "medical-drama",
                "title": 'Medical Drama',
                "authors": [
                    {
                        id: 'robin-cook',
                        title: 'Robin Cook',
                    },
                    {
                        id: 'tess-gerritsen',
                        title: 'Tess Gerritsen',
                    },
                    {
                        id: 'abraham-verghese',
                        title: 'Abraham Verghese',
                    },
                    {
                        id: 'lisa-genova',
                        title: 'Lisa Genova',
                    },
                    {
                        id: 'richard-preston',
                        title: 'Richard Preston',
                    },
                ]
            },
            {
                "id": "coming-of-age-drama",
                "title": 'Coming-of-Age Drama',
                "authors": [
                    {
                        id: 'jd-salinger',
                        title: 'J.D. Salinger',
                    },
                    {
                        id: 'john-green',
                        title: 'John Green',
                    },
                    {
                        id: 'louise-erdrich',
                        title: 'Louise Erdrich',
                    },
                    {
                        id: 'judy-blume',
                        title: 'Judy Blume',
                    },
                    {
                        id: 'khaled-hosseini',
                        title: 'Khaled Hosseini',
                    },
                ]
            },
        ],
    },
];
