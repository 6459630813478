export const inputTheme = {
    baseStyle: {
        field: {
            color: 'var(--primary-text)',
            fontSize: 14,
        }
    },
    variants: {
        outline: {
            field: {
                borderRadius: 10,
                border: "1px solid",
                backgroundColor: 'var(--primary-background)',
                borderColor: "var(--primary-button)",
                _invalid: {
                      borderColor: "var(--error-color) !important",
                      boxShadow: "0 0 0 1px var(--error-color)",
                },
                _hover: {
                    borderColor: "var(--primary-hover)"
                },
                _focus: {
                    boxShadow: 'none',
                    border: '2px solid',
                    borderColor: "var(--primary-hover)"
                },
            }
        }
    }
};
