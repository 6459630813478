import { defineStyleConfig } from '@chakra-ui/react';

export const headingTheme = defineStyleConfig({
  baseStyle: {
    fontWeight: 'normal', // Default setting
    fontFamily: '"Montserrat", sans-serif',
  },
  sizes: {
    h1: {
      fontSize: { base: '32px', md: '44px' }, // Mobile and Desktop
      lineHeight: { base: '40px', md: '60px' },
      fontWeight: 'normal',
    },
    h2: {
      fontSize: { base: '28px', md: '40px' },
      lineHeight: { base: '36px', md: '52px' },
      fontWeight: 'normal',
    },
    h3: {
      fontSize: { base: '24px', md: '32px' },
      lineHeight: { base: '32px', md: '44px' },
      fontWeight: 'normal',
    },
    h4: {
      fontSize: { base: '22px', md: '28px' },
      lineHeight: { base: '32px', md: '36px' },
      fontWeight: 'semibold', // Note change to semibold here
    },
    h5: {
      fontSize: '20px',
      lineHeight: '30px',
      fontWeight: 'semibold',
    },
    h6: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 'semibold',
    }
  },
  defaultProps: {
    size: 'h1', // Default size if none is specified
  },
});
