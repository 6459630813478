import { useNavigate } from 'react-router-dom';
import { Box, Button, Image, Container, Flex, HStack, ButtonGroup } from '@chakra-ui/react';

import { useIsBig } from '../utils/useIsBig';
import logo from '../images/logo.png';

export const ComingSoonNav = ({ loggedIn }: any) => {
    const isBig = useIsBig();

    (window as any).openRecommend = () => window.location.hash = 'recommendation';
    (window as any).closeRecommend = () => window.location.hash = '';

    const navigate = useNavigate();

    return (<>
        <Box className="header-guy" h={isBig ? '146px' : '100px'} borderBottom="1px solid var(--header-divider)" bg="var(--header-fill)" w="100%" pt="25px" color="var(--primary-text)" position="fixed" zIndex={10} shadow="0px 0px 12px 0px var(--disabled-text)">
            <Container maxW="1000px" p="0px 10px">
                <Flex minWidth='full' width="full" alignItems='center' border="1px sollid #333">
                    <HStack flexGrow={1}>
                        <Image src={logo} alt="Cat reading a book, symbolizing a community for book lovers to explore reviews, share recommendations, and discuss favorite books." h={isBig ? '97px' : '55px'} cursor="pointer" onClick={() => { navigate('/'); window.scrollTo(0, 0); }} />
                        <Box width="full" style={{ fontFamily: '"Trocchi", sans-serif', fontSize: isBig ? 40 : 25 }}>Chapter Cats</Box>
                    </HStack>
                    <ButtonGroup>
                        <Button variant="topnav" onClick={() => navigate('/')}>Home</Button>
                    </ButtonGroup>
                </Flex>
            </Container>
        </Box >
        <Box h={isBig ? '120px' : '74px'}></Box>
    </>);
}