import { extendTheme } from '@chakra-ui/react';
import { buttonTheme } from './button';
import { headingTheme } from './heading';
import { textTheme } from './text';
import { stepperTheme } from './stepper';
import { inputTheme } from './input';
import { avatarTheme } from './avatar';

export const theme = extendTheme({
    breakpoints: {
        sm: '320px',
        md: '768px',
        lg: '1024px',
        xl: '1280px',
    },
    components: {
        Button: buttonTheme,
        Heading: headingTheme,
        Text: textTheme,
        Stepper: stepperTheme,
        Input: inputTheme,
        Avatar: avatarTheme,
        Progress: {
            baseStyle: {
                filledTrack: {
                    bg: 'var(--primary-button)',
                },
            },
        },
    },
    styles: {
        '.chakra-button[data-disabled]': {
            _hover: {
                bg: '#E5E1E1',
                color: '#A39F9F',
            }
        },
        global: {
            'html, body': {
                backgroundColor: 'var(--primary-background)',
                color: '#242424',
            }
        }
    },
    fonts: {
        body: '"Montserrat", sans-serif',
    },
});