import { defineStyleConfig } from '@chakra-ui/react';

export const textTheme = defineStyleConfig({
    baseStyle: {
        fontWeight: 'normal',
    },
    sizes: {
        body1: {
            fontSize: '20px',
            lineHeight: '27px',
            fontWeight: '400',
        },
        body2: {
            fontSize: '16px',
            lineHeight: '20px',
            fontWeight: '400',
        },
        small: {
            fontSize: '12px',
            lineHeight: '16px',
            fontWeight: '400',
        },
        body1Light: {
            fontSize: '20px',
            lineHeight: '27px',
            fontWeight: 'light',
        },
        subtitle1: {
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 'semibold',
        },
        buttonLabel: {
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 'semibold',
        }
    },
    defaultProps: {
        size: 'body1',
    },
});
