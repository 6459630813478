import {
    Image,
    Box,
    Heading,
    Stack,
    Button,
    Text,
    HStack,
    useMediaQuery,
} from '@chakra-ui/react';
import { FaShoppingCart } from 'react-icons/fa';

export const BookCardComingSoon = ({ blog }: { blog: any }) => {
    const [isLargerThan750] = useMediaQuery("(min-width: 750px)");

    if (isLargerThan750) {
        return (
            <Box rounded="xl" overflow="hidden" boxShadow="2px 2px 10px -6px rgba(0,0,0,0.75)" width="100%" mb="50px">
                <Box p="30px">
                    <HStack spacing="20px">
                        <Image
                            boxShadow="2px 2px 10px -6px rgba(0,0,0,0.75)"
                            alt="Login Image"
                            width="300px"
                            src={blog.imageLink}
                        />
                        <Stack spacing={4} w="full">
                            <Heading size="h3">{blog.bookTitle}</Heading>
                            <Text>By {blog.author}</Text>
                            <Text size="sm">{blog.summary}</Text>
                            <Button onClick={() => {
                                window.open(blog.buyLink)
                            }} leftIcon={<FaShoppingCart />}>Buy Now</Button>
                        </Stack>
                    </HStack>
                </Box>
            </Box>
        );
    }
    return (
        <Box rounded="xl" overflow="hidden" boxShadow="2px 2px 10px -6px rgba(0,0,0,0.75)" width="100%" mb="50px">
            <Image
                boxShadow="2px 2px 10px -6px rgba(0,0,0,0.75)"
                alt={blog.bookTitle}
                width="full"
                src={blog.imageLink}
            />
            <Box p="30px">
                <HStack spacing="20px" mb="30px">
                    <Stack spacing={4} w="full">
                        <Text>{blog.date}&nbsp;&nbsp;|&nbsp;&nbsp;5 minute read</Text>
                        <Heading size="h5">{blog.title}</Heading>
                    </Stack>
                </HStack>
                <Text size="sm">{blog.intro}</Text>
                <Button width="full" mt="20px" onClick={() => {
                    window.open(blog.buyLink)
                }} leftIcon={<FaShoppingCart />}>Buy Now</Button>
            </Box>
        </Box>
    );
}