import { avatarAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(avatarAnatomy.keys)

const threeXL = defineStyle({
  width: "220px",
  height: "220px",
  fontSize: "6xl"
})

const sizes = {
  '3xl': definePartsStyle({ container: threeXL }),
}

export const avatarTheme = defineMultiStyleConfig({ sizes })
