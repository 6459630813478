import { Box, Input, Text } from "@chakra-ui/react";
import { InputProps } from "@chakra-ui/input";

interface LabeledInputProps extends InputProps {
    label: string;
    errorMessage?: string;
}

const LabeledInput = ({ label, errorMessage = '', ...moreProps }: LabeledInputProps) => {
    return (
        <Box position="relative" pt="8px" width="full">
            <Input {...moreProps} variant="outline" />
            <Text mt="5px" size="small" color="var(--error-color)">{errorMessage}</Text>
            <Text
                zIndex={1}
                size="small"
                position="absolute"
                top={0}
                px="3px"
                left="12px"
                background="var(--primary-background)"
                color={moreProps.isInvalid ? 'var(--error-color)' : 'var(--primary-text)'}
            >
                {label}
            </Text>
        </Box>
    );
};

export default LabeledInput;
